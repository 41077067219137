import React from "react"
import Container from "react-bootstrap/Container"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "./privacy.module.scss"

const Privacy = () => {
  return (
    <Layout>
      <SEO title="Privacy Policy" />
      <Container styleName="wrapper">
        <h1>Privacy Policy</h1>

        <p>
          <strong>SHAPE Global Ltd</strong> This Privacy Policy ("
          <strong>Policy</strong>") describes our practices of collecting and
          processing “non-personal, personal and special category ("
          <strong>sensitive</strong>") data” (collectively the ("
          <strong>data</strong>")) that you provide when using our website ("
          <strong>website</strong>") or the “survey, report and resources” you
          access while securely logged into your SHAPE account ("
          <strong>Service</strong>"). Here we explain how we collect your data,
          what we do with it, and your rights regarding your data. Because you
          own your data and choose to provide it, the terms of this Policy apply
          directly to you.
        </p>
        <p>
          DO NOT PROCEED TO USE OUR WEBSITE OR SERVICE IF YOU OBJECT TO ANY
          ASPECT OF THIS POLICY.
        </p>

        <h3>Who are we?</h3>
        <p>
          SHAPE Global Ltd, is the “Data Controller” responsible for processing
          your data. In this Privacy Policy, "<strong>we</strong>
          ", "<strong>our</strong>", or "<strong>us</strong>" refers to SHAPE
          Global Ltd. "<strong>You</strong>" and “<strong>your</strong>" refers
          to you.
        </p>

        <h3>About SHAPE</h3>

        <p>
          SHAPE is the System for Health, Attendance, Productivity and
          Engagement. Our website offers general information about what we do,
          provides broad guidance on topics related to our Service, and allows
          you to communicate with us.
        </p>
        <p>
          Our Service allows “employer organisations” (“
          <strong>employer</strong>”) to engage their staff with a comprehensive
          online survey, report and resources to improve personal, team and
          company performance.
        </p>
        <p>
          The Service is accessed securely by signing up/in via instructions
          provided to you by your employer after we have entered into a
          contractual arrangement with them. While your employer will be aware
          of your participation in the Service through aggregated reporting, we
          do not share your results with anyone other than you.
        </p>
        <p>
          Despite our best endeavours, we are not responsible for the correct
          rendering of our website or Service on all web browsers.
        </p>

        <h3>Consent</h3>
        <p>
          Our legal basis for collecting and processing your data is legitimate
          business interests and fulfilling our contractual obligation with your
          employer. We rely on factual service messages to successfully deliver
          the Service to you. These communications are not classified as
          marketing messages and are exempt from explicit consent. We will seek
          your consent to collect and/or process sensitive data, or for
          marketing or promotional purposes, or direct marketing.
        </p>

        <h3>Data we collect outside of the Service?</h3>

        <p>
          We do not track individual visitors nor collect any personal
          information when you browse our websites (
          <a href="https://www.mysha.pe">www.mysha.pe</a>. or related domains we
          operate).
        </p>
        <p>
          We do collect data you share via email directly to our general email
          addresses, such as hello@shapepowered.com, or specific employee
          addresses. We also collect and retain the data you provide through the
          contact forms on our website.
        </p>
        <p>
          When we connect with you through social media, we will collect data
          you make available through those social media channels within the
          public domain.
        </p>

        <h3>Data we collect when you use the Service</h3>
        <p>
          We automatically collect some data about your use of the Service. We
          like to know which third party sites you came from to access the
          Service, so we can offer you a personalised experience.
        </p>
        <p>
          We track your usage of the Service and collect data on date, time,
          location, frequency, duration of usage and confirmation of email
          delivery. We retain technical information about your computer or
          mobile device for system administration and analysis, including your
          IP address, URL clickstreams, unique device identifiers, operating
          system, and network and browser type to ensure our Service meets the
          needs of the technology you use.
        </p>
        <p>
          During survey completion, we collect the following data to help us
          provide an accurate report to you:
        </p>
        <ul>
          <li>
            non-personal data which includes any data we cannot use to identify
            you without coupling it with your personal data;
          </li>
          <li>personal data, such as your name and email address; and</li>
          <li>
            sensitive data, such as health status. We request your consent prior
            to collecting sensitive data.
          </li>
        </ul>
        <p>
          We perform automated processing of data we collect through the Service
          to provide a confidential report about how to improve personal, team
          and company performance.
        </p>

        <h4>How do we use your data?</h4>

        <p>The data you provide is used in the following ways:</p>

        <ul>
          <li>
            anonymised, aggregated and used for our business purposes; or{" "}
          </li>
          <li>processed and converted into reports and data analysis; or </li>
          <li>
            numerical scores that inform you of your productivity and
            performance, and how you compare with other employees. We aggregate
            and anonymise your data when sharing with any audience, and shall
            seek your approval to waive your anonymity if there is a possibility
            of identification; or
          </li>
          <li>
            research purposes for general product development and improvement
            with our academic and research partners; or{" "}
          </li>
          <li>
            ability to present you with appropriate and relevant information and
            guidance on how to improve your general health and productivity; or{" "}
          </li>
          <li>
            for advertising, marketing and public relations activities. We shall
            always request your consent to send you marketing communications,
            and you may opt-out of this whenever you wish; or{" "}
          </li>
          <li>
            to carry out communication, service, billing and administration,
            including the processing of enquires, correspondence, concerns or
            complaints you have raised; or{" "}
          </li>
          <li>
            to gather feedback on the Service and to help us evaluate and
            improve the Service; or{" "}
          </li>
          <li>
            to notify you about changes to the Service, including informing you
            about new versions, features, functionality, and service offerings;
            or{" "}
          </li>
        </ul>

        <p>
          <strong>
            The data we automatically collect is used in the following ways:
          </strong>
        </p>
        <ul>
          <li>
            to execute and upgrade the Service and improve user experience,
            including ensuring that content is presented effectively for you and
            for your computer/device; or{" "}
          </li>
          <li>
            for internal operations, including troubleshooting, testing,
            research, statistical and survey, report and resources improvement
            purposes; or{" "}
          </li>
          <li>to keep the Service safe and secure. </li>
        </ul>

        <p>
          <strong>
            The data we collect outside the Service is used in the following
            ways:
          </strong>
        </p>
        <ul>
          <li>
            for evaluation, maintenance and development of our website or
            marketing information e.g. social media; or{" "}
          </li>
          <li>
            responding to your enquiries or contacting you with the data you
            provided; or for evaluation and optimisation of our Service.{" "}
          </li>
        </ul>
        <p>
          Regardless of the above categories, it may also be necessary for us to
          process your data, where appropriate and in accordance with local laws
          and requirements, in connection with exercising or defending legal
          claims. This may arise for example where we need to take legal advice
          in relation to legal proceedings or are required by law to preserve or
          disclose certain information as part of the legal process.
        </p>

        <h5>Retaining your data</h5>
        <p>
          Your data shall be retained for two years after the date your company
          completes their contract with us. Within two years your data shall be
          anonymised, i.e. stripped of personally identifiable information
          (PII), aggregated, and used for our business purposes. This aggregated
          non-identifiable data may be stored for an extended period.
        </p>

        <h5>Data disclosure</h5>
        <p>
          We disclose data we collect from you to authorised employees,
          officers, agents, suppliers or subcontractors, insofar as is
          reasonably necessary for the purposes of performing our contractual
          obligations with your employer, and as set out in this Policy.
        </p>

        <p>We may also share your data in the following circumstances:</p>

        <ul>
          <li>
            to IT service providers who manage our IT and back office systems
            and communications networks to the extent that we are required to do
            so by law; or
          </li>
          <li>
            in connection with any legal proceedings or prospective legal
            proceedings; or
          </li>
          <li>
            in order to establish, exercise or defend our legal rights
            (including providing information to others for the purposes of fraud
            prevention and reducing credit risk); or
          </li>
          <li>
            to the purchaser (or prospective purchaser) of any business or asset
            which we are (or are contemplating) selling; or
          </li>
          <li>
            to any person who we reasonably believe may apply to a court or
            other competent authority for disclosure of that data where, in our
            reasonable opinion, such court or authority would be reasonably
            likely to order disclosure of that data.
          </li>
        </ul>
        <p>
          Except as provided in this Policy, we shall not provide your data to
          third parties.
        </p>
        <h5>Data security</h5>

        <p>
          We have implemented appropriate technical and organisational measures
          to protect the data we receive from you through communicating via the
          website and your use of the Service. We also follow accepted industry
          standards to protect the data submitted to us, both during
          transmission and once we receive it using reasonable organisational,
          technical, and administrative measures designed to protect your data.
        </p>
        <p>
          Unfortunately, no data transmission over the Internet or data storage
          system can be guaranteed to be 100% secure. If you have reason to
          believe that your interaction with us is no longer secure (for
          example, if you feel that the security of your SHAPE account has been
          compromised), please immediately notify us using the website “Contact
          Us” function, or find contact details below.
        </p>
        <p>
          The password issued to you for your SHAPE account must be treated as
          sensitive and confidential information. You are responsible for
          keeping your password safe. In the event that you suspect your SHAPE
          account (including password) has been compromised, you are responsible
          for changing your password and reporting the incident to us
          immediately.
        </p>

        <h5>Your Rights under the EU General Data Protection Regulation</h5>

        <p>
          When using the Service and submitting data to us, you have certain
          rights under the General Data Protection Regulation ("
          <strong>GDPR</strong>") and other laws. SHAPE adheres to the Data
          Protection Act 2018 which is the UK’s implementation of GDPR.
          Depending on the legal basis for processing your data, you may have
          some or all of the following rights:
        </p>

        <li>
          <b>The right to be informed</b>
          <br />
          You have the right to be informed about the data we collect from you,
          and how we process it.
        </li>
        <li>
          <b>The right of access</b>
          <br />
          You have the right to access your data.
        </li>
        <li>
          <b>The right to rectification</b>
          <br />
          You have the right to have your data corrected if it is inaccurate or
          incomplete.
        </li>
        <li>
          <b>The right to erasure (right to be forgotten)</b>
          <br />
          You have the right to request the removal or deletion of some of your
          data.
        </li>
        <li>
          <b>The right to restrict processing</b>
          <br />
          You have a right to ‘stop’ or restrict the processing of your data for
          certain purposes. When your data is restricted, we are permitted to
          store your data, but not to process it further.
        </li>
        <li>
          <b>The right to data portability</b>
          <br />
          You have the right to request a copy of your data in a machine
          readable format. You can go to the SHAPE Menu and follow the
          instructions to get your Personal Data File.
        </li>
        <li>
          <b>The right to object</b>
          <br /> You have the right to object to some of our processing of your
          data.
        </li>
        <li>
          <b>Rights in relation to automated decision-making and profiling</b>
          <br />
          You have the right to request we review our decision about you that we
          base solely on automated processing.
        </li>

        <h5>Enquiries and Complaints </h5>

        <p>
          We commit to resolve your enquiries and complaints about your privacy,
          our collection or use of your data, and any other issues you may face
          while using our website and Service. We invite you to e-mail us with
          enquiries, suggestions and complaints at:{" "}
          <a href="mailto:help@shapepowered.com">help@shapepowered.com</a>.
        </p>
        <p>
          You have the right to file a complaint with supervisory authorities if
          your data has not been processed in compliance with GDPR. If the
          supervisory authorities fail to address your complaint properly, you
          may have the right to a judicial remedy.{" "}
        </p>

        <h5 id="cookies">Our Zero cookie policy </h5>
        <p>
          Cookies are most commonly used to track website activity and visitor
          behaviour. However, at SHAPE we’ve simply decided not to use them. If
          we want to know something about your visit, we’ll just ask you. This
          is known as the Zero cookie policy. This is why we don’t ask for your
          consent to ‘accept cookies’ on our website(s).
        </p>
        <p>
          We want to be totally transparent with you. To provide a secure and
          high-performance session, a number of cookies are both recommended and
          allowed. These are called Functionality cookies (or sometime referred
          to as ‘strictly necessary cookies’) and the ICO accepts implied
          consent. These cookies help us with very practical things like
          remembering your username, region and language settings. It’s about
          doing the absolute minimum to ensure you get the best experience every
          time you visit us.
        </p>

        <h5>International data transfers</h5>
        <p>
          We are a global business. Data may be stored and processed in any
          country where we have operations or where we engage service providers.
          Service providers are limited to technical support and technical
          infrastructure, where we have entered into appropriate arrangements
          for transfer with those third-party service providers. Therefore, we
          may transfer data that we maintain about you to recipients in
          countries other than the country in which the data was originally
          collected, including to the United States. Those countries may have
          data protection rules that are different from those of your country.
          However, we have taken appropriate steps to ensure that any such
          transfers comply with applicable data protection laws and that your
          data remains protected to the standards described in this Policy. In
          certain circumstances, courts, law enforcement agencies, regulatory
          agencies or security authorities in those other countries may be
          entitled to access your data, but only with appropriate authority.
        </p>
        <p>
          If you are located in the European Economic Area (EEA) or Switzerland,
          we comply with applicable laws to provide an adequate level of data
          protection for the transfer of your data to the US.
        </p>
        <p>
          Some non-EEA countries are recognised by the European Commission as
          providing an adequate level of data protection according to EEA
          standards. For transfers from the EEA to countries not considered
          adequate in their protection of data privacy by the European
          Commission, we have ensured that adequate measures are in place,
          including by ensuring that the recipient is bound by Standard
          Contractual Clauses (SCCs), or an EU/UK-approved code of conduct or
          certification, to protect your Personal Information. SHAPE adheres to
          the principles of the EU-US and Swiss-US Privacy Shield Frameworks,
          although we no longer rely on this Framework as a legal basis for
          transfers of personal data following the judgement related to Case
          C-311/18 by the Court of Justice of the European Union. SHAPE commits
          to the use of SCCs and Additional Safeguards, as deemed appropriate.
        </p>
        <p>
          SHAPE complies with the Office of the Australian Information
          Commissioner (OAIC) in relation to cross-border data flows outlined in
          Chapter 8/APP 8, whereby an APP entity may disclose personal
          information to an overseas recipient who is subject to a law, or
          binding scheme, that has the effect of protecting the information in a
          way that, overall, is at least substantially to the way APPs protect
          the information.{" "}
        </p>

        <h5>Use of information and resources </h5>
        <p>
          During your use of the Service, you may download resources we provide.
          These resources are owned exclusively by us. You may share these
          resources strictly with work colleagues only, but not copy, publish,
          display or alter these resources in whole or part for any purpose
          whatsoever.
        </p>
        <p>
          You may comment about and re-share resources we publish via social
          media. You hereby grant us a world-wide, royalty free, fully paid up,
          irrevocable, perpetual, transferable, sublicensable license, under all
          your intellectual property rights, to use, copy, modify, transmit,
          publicly display, create derivative works of, and publish your public
          statements.
        </p>
        <p>
          Use of our website and social media information or resources is
          entirely at your own risk. While browsing our website you may see
          references and hyperlinks to third-party websites. We are not
          responsible for any third-party content.
        </p>

        <h5>Changes to this Policy</h5>

        <p>
          As we develop new products and services, deploy new technologies and
          develop new uses for information, we will review and update this
          Policy to reflect these changes. In addition, from time to time we
          make organisational, stylistic and grammatical changes to present our
          practices in a way that makes this Policy easy to read. Any changes
          may be made without prior notice and updated on this webpage.
        </p>

        <h5>Use by Minors</h5>
        <p>
          While we acknowledge some organisations may employ persons younger
          than the age of 18, the Service is not directed to individuals under
          the age of 18.
        </p>

        <h5>Lodging a complaint with the Regulator</h5>
        <p>
          <u>Relevant EEA Regulator Contact:</u> You may lodge a complaint with
          a supervisory authority competent for your country or region. Please
          click{" "}
          <a
            href="https://edpb.europa.eu/about-edpb/board/members_en"
            target="_blank"
          >
            here
          </a>{" "}
          (link is external) for contact information for such authorities.
        </p>
        <p>
          <u>Relevant non-EEA Regulator Contact:</u>
          <br />
          Australia - Privacy Commissioner - enquiries@oaic.gov.au
          <br />
          Japan - Personal Information Protection Commission -
          https://www.ppc.go.jp/en/
          <br />
          New Zealand – The Privacy Commissioner’s Officer
          enquiries@privacy.org.nz
          <br />
          South Africa – Information Regulator - inforeg@justice.gov.za
          <br />
          USA – The Federal Trade Commission -
          https://www.ftccomplaintassistant.gov
        </p>

        <h5>Contact</h5>
        <p>
          If you have any questions regarding this Policy, please write to us at
          <a href="mailto:help@shapepowered.com"> help@shapepowered.com</a>.
          Keep updated about this Policy via our website at{" "}
          <a href="https://www.mysha.pe">www.mysha.pe</a>.
        </p>

        <small className="text-muted">
          <i>Revised February 2022</i>
        </small>
      </Container>
    </Layout>
  )
}

export default Privacy
